.pond-page {
  .breadcrumbs {
    width: 100%;
    position: fixed;
    top: 63px;
    left: 0;
    background-color: #fff;
    z-index: 5;
  }

  .pond-page-card {
    margin: 24px;
    border: 1px solid #d9d9d9;

    .descr-item {
      & > div > span::after {
        display: none;
      }
    }
  }

  .descr-item {
    & > div > span::after {
      display: none;
    }
  }

  .descr-item-blue {
    & > div:first-child > span:nth-child(2) {
      color: #1890ff;
    }
  }

  .copy-icon {
    color: #1890ff;
    margin-left: 9px;

    &.block {
      position: absolute;
      top: 24px;
      right: 24px;
    }
  }

  .pond-page-body {
    padding-top: 210px;
  }

  .pp-access-segment,
  .pond-page-explore-segment {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .check,
  .reject {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
    background: #52c41a;
    border-radius: 100%;
    margin-right: 9px;

    svg {
      width: 6px;
      height: 6px;
      color: #fff;
    }
  }

  .reject {
    background: #ff4d4f;
  }

  .snippet-row {
    & > div {
      width: 100%;
      display: block;
    }

    .sr-first-comment,
    .sr-second-comment {
      font-style: italic;
    }

    .sr-first-comment {
      margin-top: 30px;
    }

    .sr-left-padding {
      padding-left: 30px;
    }
  }

  .first-divider {
    margin-top: 0;
  }

  .header-title {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .header-buttons {
    display: flex;
    .delete-btn {
      margin-right: 8px;
    }
  }

  .machine-user-form,
  .machine-roles-form {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    column-gap: 16px;

    & > div {
      margin-bottom: 0;
    }

    div.select {
      height: 40px;

      div {
        font-size: 16px;
        height: 40px;

        span {
          display: flex;
          align-items: center;
        }
      }
    }

    .input-field {
      max-width: 1200px;
      height: 40px;
    }

    & > div:first-child {
      width: 50%;

      & > div {
        width: 100%;
      }

      & > div > div:nth-child(2) {
        width: 100%;
      }
    }

    & > div:nth-child(2) {
      width: 20%;

      & > div {
        width: 100%;
      }

      & > div > div:nth-child(2) {
        width: 100%;
      }
    }

    & > div:nth-child(3) {
      width: 20%;

      & > div {
        width: 100%;
      }

      & > div > div:nth-child(2) {
        width: 100%;
      }
    }

    & > div > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .machine-user-wrapper,
  .machine-roles-wrapper {
    background: #fafafa;
    border: 1px solid #d9d9d9;
    padding: 24px;
    margin-bottom: 24px;
  }
}

.popup-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  gap: 8px;
  height: 24px;
}

.popup-question {
  display: flex;
  align-items: center;

  .anticon {
    width: 18px;
    height: 18px;
    margin-left: 0;

    svg {
      width: 18px;
      height: 18px;
      color: #fff;
      background-color: #faad14;
      border-radius: 100%;
    }
  }

  span {
    margin-left: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
}
