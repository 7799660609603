.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid #f0f0f0;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;

  .header-right {
    display: flex;
    align-items: center;
    position: relative;
  }

  .header-left {
    display: flex;
    align-items: center;
    width: 60%;
    a {
      font-weight: 400;
    }
  }

  .header-menu {
    border-bottom: none;
    .menu-item {
      line-height: 63px;
      font-size: 16px;
    }
    .menu-icon {
      vertical-align: middle;
    }
  }

  a.logo {
    font-weight: 600;
    font-size: 20px;
    background: linear-gradient(91.78deg, #52c41a 2.22%, #1493da 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-right: 15px;
  }
}

.logout-icon {
  margin-right: 8px;

  svg {
    width: 12px;
    height: 12px;
  }
}

.header-auth {
  height: 63px;
}

.header-account {
  text-align: center;
  padding: 0 24px;
  color: #1890ff;
  cursor: pointer;
}

li.header-logout {
  padding: 0;
}

.header-logout-btn {
  display: flex;
  align-items: center;
  padding: 5px 12px;
}
